import React from 'react';
import Likert from 'react-likert-scale';

import { QuizPage } from "../utils/BasePage";
import { useSnackbar } from "../utils/Snackbar";
import { sanitize_saved_answer } from "../utils/answer_sanitizer";

import translations from "../jsonData/translations.json";
import questionsResponses from "../jsonData/QuestionsResponses.json"
import relevanceResponses from "../jsonData/RelevanceResponses.json"

import "../css/QuestionPage.css";

const Question = (props) => {

    // Define needed Variables
    let id = props.cur_id;
    const data_array = props.data_array;
    const data = data_array[id];

    const snackbar = useSnackbar();


    // Count which Question we are currently on
    // We can't use ID for that, since ID also counts Snacks and Activities, so we need to do that manually
    let all_question_amount = data_array.filter(entry => entry.type === "question").length;
    // Take Data_arrays data up to and including id, filter out every non-question-entry, length is amount of questions
    let current_question_number = data_array.slice(0, id + 1).filter(entry => entry.type === "question").length;


    const clickNext = () => {
        /* Function for the Next button. Checks whether continuing is allowed */

        //Accesses sessionStorage to see whether answers exist (and whether they are valid and sanitized or not)
        if (sanitize_saved_answer(sessionStorage.getItem("question" + (id)), "question") !== null
            && sanitize_saved_answer(sessionStorage.getItem("relevance" + (id)), "relevance") !== null) {
            //If so, position can be changed
            props.change_pos(id + 1);
        }
        else {
            // Otherwise, open our own snackbar created by the Hook and tell the user to answer
            snackbar({ id: "question_answer", text: props.theming.text.snack_bar_answer_needed[props.lang] });
        }

    };

    const nextButton = (
        // Custom Next button which calls our clickNext function checking whether the question is answered
        <button
            className="btn btn-secondary"
            id="nextButton"
            type="button"
            onClick={clickNext}
        >
            {/* Of course, proper translation for Button */}
            {translations[props.lang].buttons.next}
        </button>
    );

    const getLikertJsonData = (key) => {
        /*
        Function for constructing the data needed for the Likert Component
        Takes a key (either "question" or "relevance"), accesses the .json files
        QuestionsResponses.json or RelevanceResponses.json depending on the key and given language,
        then sets every .checked to false, and looks in the session storage whether an answer exists already
        */

        let answers;
        if (key === "question") {
            answers = questionsResponses[props.lang];
        } else {
            answers = relevanceResponses[props.lang];
        }

        // The checked = false needs to be done because answers seems to be cached between different questions
        answers.forEach(entry => entry.checked = false);

        // If an Answer already exists, apply it
        let saved_answer = sanitize_saved_answer(sessionStorage.getItem(key + (id)), key);
        if (saved_answer !== null) {
            answers[saved_answer].checked = true;
        }

        return answers;
    }

    const changeSavedItem = (key, change) => {
        /* Sets sessionStorage for relevant key */
        sessionStorage.setItem(key + id, change.value);
    }


    const renderLikert = (key) => {
        /* Construct a Likert Component for either Question or Relevance, depending on Key */

        let question_answers = getLikertJsonData(key);

        return (
            //Div with key is needed here, so that the Likert Component is rerendered for every page
            <div key={key + id}>
                <Likert
                    id={key + id}
                    responses={question_answers}
                    onChange={change => changeSavedItem(key, change)}
                    title={translations[props.lang].accessibility[key]}
                    aria-labelledby={key + id}
                />
            </div>
        )
    }

    return (

        <QuizPage
            {...props}
            nextButton={nextButton}
        >

            <main className="row">

                {/* QuestionSection. Takes up the whole width if screen is small or extra small, else half of it */}
                <div className="col-12 col-sm-12 col-md-6">

                    <div className="questionSection">

                        {/* Question Counter displaying which question we are on */}
                        <p className="question-counter">
                            {translations[props.lang].questions.counter + " " + current_question_number + "/" + all_question_amount}
                        </p>

                        <h1 className="question-text" >
                            {/* If no proper translation exists, just take the german one. */}
                            {data["text_" + props.lang] === "" ? data["text_de"] : data["text_" + props.lang]}
                        </h1>

                    </div>
                </div>

                {/* LikertQuestion. Just as Question, takes up Width when Screen is Small or ExtraSmall, else half of it */}
                <div className="col-12 col-sm-12 col-md-6 answerSection">

                    {/* Box for Answer Selection */}
                    <div className="answerBox">
                        {renderLikert("question")}
                    </div>

                    {/* Box for Relevance Selection */}
                    <div className="question-relevance">
                        <div className="justify-content-center row">

                            {/* Custom Title for Likert Scala */}
                            <p id="relevance-text">
                                {translations[props.lang].questions.relevance}
                            </p>

                            <div className="relevance-likert">
                                {renderLikert("relevance")}
                            </div>

                        </div>
                    </div>

                </div>

            </main>

        </QuizPage>
    );
}


export default Question;