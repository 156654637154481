import React from "react";

import HeaderBar from "./HeaderBar";
import FooterBar from "./FooterBar";

import "../css/WelcomePage.css"
import { Impressum } from "./Impressum";

const WelcomeBasePage = (props) => {

    // If no Background image exists, a few CSS classes need to be deactivated
    let background_className = "";
    let app_body_colour = "";
    if (props.theming["--welcome-background-img"] !== null) {
        background_className = "welcome-bg";
        app_body_colour = "welcome-screen-colour";
    }

    return (

        // Background Image and Positioning
        // fluid container to wrap around row (doesn't look like we need it, website looks the same without, but docs say it's needed)
        <div className={"container-fluid " + background_className}>


            {/* Positioning in Middle */}
            <div className="row justify-content-center">

                {/* 
                Half Transparent Box with Content in it. The wrapper-class positions it 3rem from the top (on large screens) 
                justifyContent is overwritten to be space-between here, so that the contents of WelcomePage spread over the whole screen
                */}
                <div className={"app-body wrapper " + app_body_colour} style={{ justifyContent: "space-between" }}>

                    <div style={{ justifyContent: "space-between" }}>

                        {/* HeaderBar with LanguageSelect and Small Logo */}
                        <HeaderBar {...props} renderProgressBar={false} />

                        {/* WelcomePage and ResultPage are passed through here */}
                        {props.children}
                    </div>

                    <div>
                        <FooterBar {...props} />
                    </div>

                    <Impressum marginTop="2" link={props.theming.text.impressum_link.de} />

                </div>
            </div>
        </div>
    )
}

export default WelcomeBasePage
