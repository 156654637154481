import React from "react";
import { ListGroup, Spinner } from 'react-bootstrap';
import HtmlMapper from 'react-html-map';

import WelcomeBasePage from "../utils/WelcomeBasePage";
import { get_saved_answer_for_backend } from "../utils/answer_sanitizer";

import translations from "../jsonData/translations.json";
import html_mapper from "../utils/html_mapper";
import { hexToRGB, GetFontColorForBackground } from "../utils/colours";

import "../css/ResultPage.css"

class Result extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sports: [],
            is_mobile_view: window.matchMedia("(max-width:576px)").matches,
            showExplainerText: false,
            error: null,
        }


        // .matches is true if page is in Desktop view, false otherwise
        // Used to position the Post Text correctly
        this.post_text_render_pos = window.matchMedia("(max-width:767px)");

        this.fetchSport = this.fetchSport.bind(this);
        this.updateIsMobileView = this.updateIsMobileView.bind(this);

    }

    componentDidMount = () => {

        //Event Listener so position of text can be changed dynamically
        this.post_text_render_pos.addEventListener("change", this.updateIsMobileView);

        // If a Sport List already exists in the history state (-> the site was reached through back clicking)
        // Use that sport List instead of fetching a new one
        if (window.history.state !== null) {
            let new_sports = window.history.state.sports;

            if (Array.isArray(new_sports) && new_sports.length > 0) {

                this.setState({ sports: new_sports });
                window.history.replaceState({ cur_id: this.props.cur_id, sports: new_sports }, "Quiz", "");

                return;
            }
        }

        // Normal Sport fetching
        this.fetchSport()
    }

    clickBack = () => {
        this.props.change_pos(this.props.cur_id - 1)
    };

    updateIsMobileView() {
        this.setState({ is_mobile_view: window.matchMedia("(max-width:767px)").matches });
    }

    fetchSport() {
        // Order of Business:
        // 1. Go through the Whole Quiz which lies in this.props.data_array
        //    For every Question that's in there, go into the sessionStorage and grab its answers,
        //    along with the question pk from the data_array
        // 2. Construct a Request with the gathered data as json
        // 3. Send that to the Backend. If correct data comes back, throw it into the state to be shown

        let data = []
        this.props.data_array.forEach((entry, index) => {
            if (entry.type !== "question") {
                return
            }

            let response = {};
            response.pk = entry.pk;
            response.answer = get_saved_answer_for_backend("question", index);
            response.relevance = get_saved_answer_for_backend("relevance", index);

            data.push(response);

        });

        const request = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }

        fetch(this.props.backend_ip, request)
            .then(response => response.json())
            .then(
                (result) => {

                    // Go through every sport, and for each of the three top criteria, check
                    // If it exists, change it: Right now it's a tuple with (name, score), only use the name
                    result.forEach((sports) =>
                        sports.top_criteria = sports.top_criteria.map((criterium) => {
                            return criterium[0];
                        }
                        )
                    )

                    this.setState({ sports: result, error: null });
                    // Write that Result list into the History State so that it can be reached through back clicking
                    window.history.replaceState({ cur_id: this.props.cur_id, sports: result }, "Quiz", "");
                },
                (error) => {
                    // Error Reporting goes here
                    this.setState({ sports: [], error: error });
                }
            );
    }

    getPostText(end_text, lang) {
        return (
            <div className="col-10 ql-editor anti-quill result-text-bottom">
                <HtmlMapper html={end_text.post[lang]}>
                    {html_mapper}
                </HtmlMapper>
            </div>
        )
    }

    logSportClick(sportArg) {

        let content = new Blob([
            JSON.stringify({
                sport_id: sportArg.pk
            })
        ], {
            type: 'application/json'
        });

        navigator.sendBeacon(this.props.backend_ip + "clicked/", content)
    }

    renderSportList() {

        if (this.state.error !== null) {
            // If there was an error while fetching, give Error Report

            return (
                <ListGroup.Item className="list-item" style={{ minHeight: "30rem" }}>
                    <br />
                    <h2>:/</h2>
                    <p>Konnte Sportliste nicht vom Server bekommen! Bitte versuche es später nocheinmal.</p>
                    <p>Wenn das öfter passiert, bitte benachrichtige den Unisport oder die relevante IT-Person.</p>
                    <br />
                    <p>Fehler: {`${this.state.error}`}</p>

                    {/* Button for allowing refetching of sports */}
                    <button
                        className="btn"
                        type="button"
                        id="result-nextButton"
                        style={{ width: "100%", height: "unset", minHeight: "2.4rem" }}
                        onClick={this.fetchSport}
                    >
                        Sportarten Nochmal Laden
                    </button>
                </ListGroup.Item>
            )
        }

        if (this.state.sports.length === 0) {
            // The sports are still being loaded, so display a Loading animation

            return (
                <ListGroup.Item style={{ backgroundColor: "#00000000", border: "0px", height: "10rem" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>

                        <Spinner animation="border" role="status" style={{ marginTop: "4rem" }}>
                            <span className="visually-hidden">Lade...</span>
                        </Spinner>

                    </div>
                </ListGroup.Item>
            )
        }

        // Feature: Unisport wants the ListCells background to fade out with the percentage of the sportrating
        // Meaning we need to set the background-color opacity in the style with the percentage
        // For this, we need to convert the HEX color given by the theming to RGB
        let b_col = hexToRGB(this.props.theming.colour["--result-list-secondary"]);

        // Goes over every Sport given by the Backend and assembles a list Entry for it
        return this.state.sports.map((sportArg, index) => {

            let percentage = sportArg.rating / this.state.sports[0].rating * 100;

            // If only 0es are clicked, division by zero happens, so we need to check this
            if (isNaN(percentage)) {
                percentage = 100;
            }

            let pillColor = GetFontColorForBackground(this.props.theming.colour["--result-list-criterion-bg-colour"]);

            return (
                <a className="custom-link" href={sportArg.link} key={sportArg.name} target="_blank" rel="noreferrer" onClick={() => this.logSportClick(sportArg)} onAuxClick={() => this.logSportClick(sportArg)} >
                    {/* Key is a needed prop here to differentiate the different items
                    Funnily enough, I have that same problem in the Admin Frontend, but it's not fixable there */}
                    <ListGroup.Item className="list-item" key={sportArg.name} style={{ backgroundColor: `rgba(${b_col.red}, ${b_col.green}, ${b_col.blue}, ${percentage}%)` }}>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                            <div style={{ overflow: "hidden" }}>
                                <div>
                                    <p className="custom-link-col" style={{ overflow: "hidden", textOverflow: "ellipsis", wordWrap: "break-word", wordBreak: "keep-all", fontSize: "1.5em", fontWeight: 500 }}>
                                        {sportArg.name}
                                    </p>
                                </div>

                                <span className="badge rounded-pill badge-result" style={{ marginLeft: "3px", color: pillColor }}>
                                    {sportArg.top_criteria[0] ? sportArg.top_criteria[0] : ""}
                                </span>
                                <span className="badge rounded-pill badge-result" style={{ marginLeft: "3px", color: pillColor }}>
                                    {sportArg.top_criteria[1] ? sportArg.top_criteria[1] : ""}
                                </span>
                                <span className="badge rounded-pill badge-result" style={{ marginLeft: "3px", color: pillColor }}>
                                    {sportArg.top_criteria[2] ? sportArg.top_criteria[2] : ""}
                                </span>
                            </div>

                            <div style={{ width: "5rem", textAlign: "right", fontSize: "1.5em", fontWeight: 500 }}>
                                {Math.round(percentage) + "%"}
                            </div>

                        </div>

                        {/* Bar indicating how good the sport is based on the relative distance to the top sport */}
                        <div style={{
                            width: `calc(${percentage}% + 2rem)`,
                            height: "5px",
                            backgroundColor: "var(--result-list-primary)",
                            marginLeft: "-1rem",
                            marginRight: "-1rem",
                            marginBottom: "-0.5rem",
                            marginTop: "0.5rem",
                            // If the entry is the last one in the list, be sure to keep the border rounding of the list
                            borderRadius: index === this.state.sports.length - 1 ? "0 0 0 4px" : "0 0 0 0",
                        }}>

                        </div>

                    </ListGroup.Item>
                </a>
            )
        })

    }

    renderExplainerText() {
        let visibility = "visible";
        let opacity = "100%"

        if (!this.state.showExplainerText) {
            visibility = "hidden";
            opacity = "0%"
        }

        return (
            <div style={{

                visibility: visibility,
                opacity: opacity,
                transition: "visibility 0.1s ease-in-out 0.0s, opacity 0.1s ease-in-out 0s",

                color: "var(--text-primary)",
                backgroundColor: "#ebebeb",
                textAlign: "justify",
                fontSize: "15px",
                lineHeight: "1.5em",
                boxShadow: "0px 0px 10px var(--result-list-primary)",
                position: "absolute",
                top: "18px",
                left: "16px",
                zIndex: 1,
                width: "calc(100% - 32px)",
                border: "1px solid var(--result-list-primary)",
                borderRadius: "4px",
                paddingLeft: "min(40px, 5%)",
                paddingRight: "min(40px, 5%)",
                paddingTop: "20px",
                paddingBottom: "20px",
            }}>
                <div
                    aria-hidden="true"
                    style={{
                        position: "absolute",
                        top: "5px",
                        right: "3px",
                        width: "25px",
                        aspectRatio: "1 / 1",
                        textAlign: "center",
                        fontSize: "16px",
                    }}>
                    &times;
                </div>
                {this.props.theming.text.result_list_explainer[this.props.lang]}
            </div>
        )
    }


    render() {

        return (

            // WelcomeBasePage including Background Image, Transparent Container, and HeaderBar
            <WelcomeBasePage
                {...this.props}
            >

                {/* Row Containing all Content */}
                <main className="row">

                    {/* Left side of ResultPage including the EndText and the Backbutton */}
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">

                        <div className="result-left">

                            <div className="ql-editor anti-quill result-text">
                                <HtmlMapper html={this.props.end_texts.pre[this.props.lang]}>
                                    {html_mapper}
                                </HtmlMapper>
                            </div>

                            <nav className="result-buttons">

                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    id="result-backButton"
                                    onClick={this.clickBack}
                                >
                                    {translations[this.props.lang].buttons.back}
                                </button>

                                <button
                                    className="btn"
                                    type="button"
                                    id="result-nextButton"
                                    onClick={this.props.reset}
                                >
                                    {translations[this.props.lang].buttons.reset}
                                </button>

                            </nav>

                            {
                                (!this.state.is_mobile_view) ? this.getPostText(this.props.end_texts, this.props.lang) : null
                            }
                        </div>
                    </div>

                    {/* Right side of ResultPage: The List */}
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5">

                        {/* Big List including all Sports 
                            The MinHeight is set so that the picture doesn't snap when the list pops in
                        */}
                        <section>
                            <ListGroup className="result-sports-list" style={{ minHeight: "60rem" }}>

                                {/* First Entry in the List: The Title of the List */}
                                <ListGroup.Item className="list-title" style={{ display: "flex", alignItems: "stretch", justifyContent: "space-between" }}>
                                    <div style={{ width: "85%", alignSelf: "center" }}>
                                        {/* {translations[this.props.lang].results.table_header} */}
                                        {this.props.theming.text.result_list_header[this.props.lang]}
                                    </div>
                                    <div style={{ width: "10%", marginTop: "10px", marginBottom: "10px" }}>
                                        <button
                                            className="btn"
                                            type="button"
                                            style={{
                                                color: "var(--result-list-title-font)",
                                                border: "1px solid var(--result-list-title-font)",
                                                width: "min(100%, 3em)",
                                                aspectRatio: "1 / 1",
                                                padding: "0px",
                                                float: "right",
                                            }}
                                            onClick={() => { this.setState({ showExplainerText: !this.state.showExplainerText }) }}
                                        >
                                            {this.renderExplainerText()}
                                            ?
                                        </button>
                                    </div>
                                </ListGroup.Item>

                                {this.renderSportList()}

                            </ListGroup>
                        </section>

                    </div>

                    {
                        (this.state.is_mobile_view) ? this.getPostText(this.props.end_texts, this.props.lang) : null
                    }

                </main>

            </WelcomeBasePage>
        );
    }
}

export default Result;
