import React from "react";
import HeaderBar from "./HeaderBar";
import translations from "../jsonData/translations.json";
import { Impressum } from "./Impressum";



class BasePage extends React.Component {
    /* BasePage Component
        Takes the Content of a Quiz Site, eg Question or Wissenssnack as a child and renders them in a Box (depending on Screen size)
        Additionally, provides Headerbar with Language Selection and the UniSport Logo
    */

    render() {

        return (

            // fluid container to wrap around row (doesn't look like we need it, website looks the same without, but docs say it's needed)
            <div className="container-fluid">

                {/* Row containing app-body. Also centers everything */}
                <div className="row justify-content-center">

                    <div className="app-body wrapper">
                        {/* Style for app-body:
                            Column Flex, with flex-start as justifying

                            HeaderBar is first, then the content. Content contains everything else, including Body and Buttons

                            The Wrapper simply puts a margin of 3 rem above the app-body,
                            to create the illusion thet the body is kind of in the middle of the screen
                        */}


                        {/* Main Body, with Header and Children. Since both are in the same Div, both are as high in app-body as possible */}


                        <HeaderBar {...this.props} />


                        {this.props.children}

                        <Impressum link={this.props.theming.text.impressum_link.de} />
                    </div>
                </div>
            </div>

        )
    };
}



export const QuizPage = (props) => {
    /**
     * Wrapper for all Quiz Pages
     * Doesn't do much, but places the Buttons at the bottom of the page.
     * This was once the job of BasePage, but since that holds the header which needs to be persistant between pages,
     * this now is the Component for the job.
     */


    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
            {props.children}
            <BaseButtonComponent {...props} />
        </div>
    )

}


export class BaseButtonComponent extends React.Component {
    /*
        Base Button Component
        Provides default Next and Back, along with a slot for a custom Bottom Component (Progress Bar for Activities)
        All of those can be overwritten with other buttons.
        Takes, next to the default props of App.js, to embed in the ButtonComponent:
        nextButton (Component)
        backButton (Component)
        bottomComponent (Component)
    */

    clickNext = () => {
        this.props.change_pos(this.props.cur_id + 1)
    };

    clickBack = () => {
        this.props.change_pos(this.props.cur_id - 1)
    };

    nextButtonProvider = () => {
        /* Checks whether a custom button was given, otherwise returns a generic Next Button */

        if (this.props.nextButton !== undefined) {
            return this.props.nextButton
        }

        return (
            <button
                className="btn btn-secondary"
                id="nextButton"
                type="button"
                onClick={this.clickNext}
            >
                {translations[this.props.lang].buttons.next}
            </button>
        )
    }

    backButtonProvider = () => {
        /* Checks whether a custom button was given, otherwise returns a generic Back Button */

        if (this.props.backButton !== undefined) {
            return this.props.backButton
        }

        return (
            <button
                className="btn btn-secondary"
                id="backButton"
                type="button"
                onClick={this.clickBack}
            >
                {translations[this.props.lang].buttons.back}
            </button>
        )
    }

    renderBottomComponent = () => {
        /* Renders a custom Component just above the Next and Back buttons 
        Only used for ProgressBar of Activities right now, but could be useful in other applications as well
        */

        if (this.props.bottomComponent !== undefined) {
            return this.props.bottomComponent()
        } else {
            return null;
        }
    }


    render() {
        return (

            <nav className="row justify-content-center">


                {this.renderBottomComponent()}

                <div id="footer" style={{ display: "flex", justifyContent: "space-around", width: "80%", marginBottom: "3rem" }}>

                    <div>
                        {this.backButtonProvider()}
                    </div>

                    <div>
                        {this.nextButtonProvider()}
                    </div>

                </div>
            </nav>
        )
    }
}


export default BasePage
