import questionsResponses from "../jsonData/QuestionsResponses.json"
import relevanceResponses from "../jsonData/RelevanceResponses.json"

export const sanitize_saved_answer = (value, key) => {
    /**
     * Gets the value of an answer and checks whether it is a valid number or has been meddled with
     * If it isn't valid, returns null. Relevant for QuestionPage, where you can only continue if the answer is valid
     */

    if (value < 0 ||
        value === null ||
        value % 1 !== 0
    ) {
        return null;
    }

    // Instead of hardcoding the count of possible answers, we read the JSON to check the length
    // A tad more expensive, but I think well worth it for the flexibility 
    let max_val = key === "question" ? questionsResponses["de"].length : relevanceResponses["de"].length;

    if (value > max_val) {
        return null;
    }

    return value;
}

export const get_saved_answer_for_backend = (key, index) => {
    /**
     * Formats answer in a way fitting for sending to the backend
     * key: Either "question" or "relevance"
     * index: Index of the Question in the Data Array  
     */
    let num = parseInt(sanitize_saved_answer(sessionStorage.getItem(key + index), key));

    if (isNaN(num)) {
        num = 0;
    }

    return num;
}